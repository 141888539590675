define("@glimmer/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assert = debugAssert;
  _exports.assign = assign;
  _exports.fillNulls = fillNulls;
  _exports.ensureGuid = ensureGuid;
  _exports.initializeGuid = initializeGuid;
  _exports.dict = dict;
  _exports.unwrap = unwrap;
  _exports.expect = expect;
  _exports.unreachable = unreachable;
  _exports.EMPTY_ARRAY = _exports.ListSlice = _exports.ListNode = _exports.LinkedList = _exports.EMPTY_SLICE = _exports.DictSet = _exports.Stack = void 0;

  function unwrap(val) {
    if (val === null || val === undefined) throw new Error("Expected value to be present");
    return val;
  }

  function expect(val, message) {
    if (val === null || val === undefined) throw new Error(message);
    return val;
  }

  function unreachable(message = 'unreachable') {
    return new Error(message);
  } // import Logger from './logger';
  // let alreadyWarned = false;


  function debugAssert(test, msg) {
    // if (!alreadyWarned) {
    //   alreadyWarned = true;
    //   Logger.warn("Don't leave debug assertions on in public builds");
    // }
    if (!test) {
      throw new Error(msg || 'assertion failure');
    }
  }

  var {
    keys: objKeys
  } = Object;

  function assign(obj) {
    for (var i = 1; i < arguments.length; i++) {
      var assignment = arguments[i];
      if (assignment === null || typeof assignment !== 'object') continue;
      var keys = objKeys(assignment);

      for (var j = 0; j < keys.length; j++) {
        var key = keys[j];
        obj[key] = assignment[key];
      }
    }

    return obj;
  }

  function fillNulls(count) {
    var arr = new Array(count);

    for (var i = 0; i < count; i++) {
      arr[i] = null;
    }

    return arr;
  }

  var GUID = 0;

  function initializeGuid(object) {
    return object._guid = ++GUID;
  }

  function ensureGuid(object) {
    return object._guid || initializeGuid(object);
  }

  function dict() {
    return Object.create(null);
  }

  class DictSet {
    constructor() {
      this.dict = dict();
    }

    add(obj) {
      if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
      return this;
    }

    delete(obj) {
      if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
    }

  }

  _exports.DictSet = DictSet;

  class Stack {
    constructor() {
      this.stack = [];
      this.current = null;
    }

    get size() {
      return this.stack.length;
    }

    push(item) {
      this.current = item;
      this.stack.push(item);
    }

    pop() {
      var item = this.stack.pop();
      var len = this.stack.length;
      this.current = len === 0 ? null : this.stack[len - 1];
      return item === undefined ? null : item;
    }

    isEmpty() {
      return this.stack.length === 0;
    }

  }

  _exports.Stack = Stack;

  class ListNode {
    constructor(value) {
      this.next = null;
      this.prev = null;
      this.value = value;
    }

  }

  _exports.ListNode = ListNode;

  class LinkedList {
    constructor() {
      this.clear();
    }

    head() {
      return this._head;
    }

    tail() {
      return this._tail;
    }

    clear() {
      this._head = this._tail = null;
    }

    toArray() {
      var out = [];
      this.forEachNode(n => out.push(n));
      return out;
    }

    nextNode(node) {
      return node.next;
    }

    forEachNode(callback) {
      var node = this._head;

      while (node !== null) {
        callback(node);
        node = node.next;
      }
    }

    insertBefore(node, reference = null) {
      if (reference === null) return this.append(node);
      if (reference.prev) reference.prev.next = node;else this._head = node;
      node.prev = reference.prev;
      node.next = reference;
      reference.prev = node;
      return node;
    }

    append(node) {
      var tail = this._tail;

      if (tail) {
        tail.next = node;
        node.prev = tail;
        node.next = null;
      } else {
        this._head = node;
      }

      return this._tail = node;
    }

    remove(node) {
      if (node.prev) node.prev.next = node.next;else this._head = node.next;
      if (node.next) node.next.prev = node.prev;else this._tail = node.prev;
      return node;
    }

  }

  _exports.LinkedList = LinkedList;

  class ListSlice {
    constructor(head, tail) {
      this._head = head;
      this._tail = tail;
    }

    forEachNode(callback) {
      var node = this._head;

      while (node !== null) {
        callback(node);
        node = this.nextNode(node);
      }
    }

    head() {
      return this._head;
    }

    tail() {
      return this._tail;
    }

    toArray() {
      var out = [];
      this.forEachNode(n => out.push(n));
      return out;
    }

    nextNode(node) {
      if (node === this._tail) return null;
      return node.next;
    }

  }

  _exports.ListSlice = ListSlice;
  var EMPTY_SLICE = new ListSlice(null, null);
  _exports.EMPTY_SLICE = EMPTY_SLICE;
  var EMPTY_ARRAY = Object.freeze([]);
  _exports.EMPTY_ARRAY = EMPTY_ARRAY;
});